import React, { useState } from "react";

import { Drawer, Typography } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { drawerWidth } from "../../styleVariables";
import SidebarItem from "./SidebarItem";
import Menus from "./components/Menus";

import { useDispatch } from "react-redux";

// const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    maxWidth: drawerWidth,
    height: "100%",
    zIndex: theme.zIndex.drawer + 99,
  },
  modal: {
    [theme.breakpoints.down("sm")]: {
      top: "56px!important",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px!important",
    },
    zIndex: "1000!important",
  },
  backdrop: {
    [theme.breakpoints.down("sm")]: {
      top: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
  },
  drawer: {
    width: drawerWidth,
    overflow: "hidden",
    flexShrink: 0,
    whiteSpace: "nowrap",
    color: "#fff",
    backgroundColor: "red",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    color: "white",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },

    bottomPush: {
      position: "fixed",
      bottom: 0,
      textAlign: "center",
      paddingBottom: 10,
      minHeight: 200,
    },
  },
}));

const Sidebar = ({
  opened,
  toggleDrawer,
  routes,
  location,
  userDetails,
  setToggleMenuWithHover,
  handleClose,
  modules,
}) => {
  const classes = useStyles();
  const [activeRoute, setActiveRoute] = useState(undefined);
  const toggleMenu = (index) =>
    setActiveRoute(activeRoute === index ? undefined : index);

  // const [modules, setModules] = useState(null);

  // const modules = useSelector((state) => state?.menuList?.modules);



  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   if (userDetails) {
  //     dispatch(downloadModulesAction(userDetails));
  //     // axios
  //     //   .post("https://stepup-trade-api.clonestudiobd.com/api/app_menus/by_user", {
  //     //     user_id: userDetails?.id,
  //     //   })
  //     //   .then(function (response) {
  //     //     setModules(response.data);
  //     //   })
  //     //   .catch(function (error) {
  //     //    
  //     //   });
  //   }
  // }, [userDetails]);


  React.useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  const menu = (
    <List component="nav">
      {routes.map((route, index) => {
        const isCurrentPath =
          location.pathname.indexOf(route.path) > -1 ? true : false;
        return (
          <SidebarItem
            key={index}
            index={index}
            route={route}
            activeRoute={activeRoute}
            toggleMenu={toggleMenu}
            currentPath={isCurrentPath}
          />
        );
      })}
    </List>
  );

  return (
    <div syle={{ top: 64 }}>
      <Hidden smDown>
        {/* <Drawer
          variant="persistent"
          classes={{
            paper: classes.drawerPaper
          }}
          open={opened}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop
            },
            onBackdropClick: toggleDrawer
          }}
        >
          {menu}
        </Drawer> */}
        <Drawer
          variant={"permanent"}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: opened,
            [classes.drawerClose]: !opened,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: opened,
              [classes.drawerClose]: !opened,
            }),
          }}
          open={opened}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop,
            },
            onBackdropClick: toggleDrawer,
          }}
        >
          <div className={classes.toolbar} />
          <Menus
            sidebarOpen={opened}
            handleClose={handleClose}
            setToggleMenuWithHover={setToggleMenuWithHover}
            modules={modules ? modules : []}
          />
          <div className={classes.bottomPush}>
            <Typography>Footer</Typography>
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={opened}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          // disableBackdropTransition={!iOS}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop,
            },
            onBackdropClick: toggleDrawer,
          }}
        >
          <Menus
            handleClose={handleClose}
            setToggleMenuWithHover={setToggleMenuWithHover}
            sidebarOpen={opened}
            modules={modules ? modules : []}
          />
        </SwipeableDrawer>
      </Hidden>
    </div>
  );
};

Sidebar.prototypes = {
  opened: PropTypes.func,
  toggleDrawer: PropTypes.func,
  closeDrawer: PropTypes.func,
  openDrawer: PropTypes.func,
  routes: PropTypes.object,
};

const SidebarWithRouter = withRouter(Sidebar);

export default withWidth()(SidebarWithRouter);
