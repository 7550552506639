import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MyDocument from "./MyDocument";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);

  React.useEffect(() => {
    document.title = "Customer Ledger";
  }, []);

  const location = useLocation();

  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  const getCustomerInfo = localStorage.getItem("customer_ledger")
    ? JSON.parse(localStorage.getItem("customer_ledger"))
    : null;

  useEffect(() => {
    if (location) {
      const getData = localStorage.getItem("company-info")
        ? JSON.parse(localStorage.getItem("company-info"))
        : null;
      setCompanyData(getData);
    }
  }, [location]);

  useEffect(() => {
    fetch("https://step-up-api.clonestudiobd.com/api/companies")
      .then((res) => res.json())
      .then((data) => {
        const filterdData = data.data.filter(
          (item) => item.id === companyData?.id
        );
        setCompanyInfo(filterdData);
      });
  }, [companyData]);

  React.useEffect(() => {
    if (getCustomerInfo) {
      fetch(`https://stepup-trade-api.clonestudiobd.com/api/doc/customer_ledgers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(getCustomerInfo),
      }).then((response) => {
        response.json().then((result) => {
          setInvoiceData(result);
        });
      });
    }
  }, []);

  console.log("invoiceData", invoiceData);

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      <MyDocument
        invoiceData={invoiceData ? invoiceData : []}
        companyInfo={companyInfo}
        getInfo={getInfo}
        getSummInfo={getCustomerInfo[0]}
      />
    </PDFViewer>
  );
};

export default Invoice;
