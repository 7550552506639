import axiosClient from "../axiosClient";

//app menus
export async function retrieveAppModulesData(value) {
  let postData = {
    p_id: value?.user_level_id,
    p_user_for: 1,
  };

  return await axiosClient.post("/app_menus/get_by_user", postData);
}

//users
export async function retrieveUsersData() {
  return await axiosClient.get("/users");
}

//login
export async function loginDB(value) {

  return await axiosClient.post("/auth/login", value);
}

//permission
export async function permissionDB(value) {
  return await axiosClient.post("/app_menus/permissions", value);
}

export async function addUserDB(value) {
  var bodyFormData = new FormData();

  bodyFormData.append("first_name", value.first_name);
  bodyFormData.append("last_name", value.last_name);
  bodyFormData.append("phone_no", value.phone_no);
  bodyFormData.append("email", value.email);
  bodyFormData.append("user_id", value.user_id);
  bodyFormData.append("password", value.password);
  bodyFormData.append("user_password", value.user_password);
  bodyFormData.append("user_status", value.user_status);
  bodyFormData.append("user_types", value.user_types);
  bodyFormData.append("user_for", value.user_for);
  bodyFormData.append("user_level_id", value.user_level_id);
  bodyFormData.append("supervisor_id", value.supervisor_id);

  return await axiosClient.post("/users", bodyFormData);
}

export async function deleteUserDB(id) {
  return await axiosClient.delete(`/users/${id}`);
}

export async function bulkDeleteUserDB(ids) {
  return await axiosClient.post(`/users/bulk_delete`, ids);
}

export async function editUserDB(value) {
  var bodyFormData = new FormData();

  bodyFormData.append("first_name", value.first_name);
  bodyFormData.append("last_name", value.last_name);
  bodyFormData.append("phone_no", value.phone_no);
  bodyFormData.append("email", value.email);
  bodyFormData.append("user_id", value.user_id);
  bodyFormData.append("password", value.password);
  bodyFormData.append("user_password", value.user_password);
  bodyFormData.append("user_status", value.user_status);
  bodyFormData.append("user_types", value.user_types);
  bodyFormData.append("user_for", value.user_for);
  bodyFormData.append("user_level_id", value.user_level_id);
  bodyFormData.append("supervisor_id", value.supervisor_id);
  bodyFormData.append("photo", value.photo);
  bodyFormData.append("sign_photo", value.sign_photo);

  return await axiosClient.put(`/users/${value.id}`, value);
}

//banks
export async function retrieveBankDB() {
  return await axiosClient.get("/banks");
}

export async function addBankDB(value) {
  return await axiosClient.post("/banks", value);
}

export async function deleteBankDB(id) {
  return await axiosClient.delete(`/banks/${id}`);
}

export async function bulkDeleteBankDB(ids) {
  return await axiosClient.post(`/banks/bulk_delete`, ids);
}

export async function editBankDB(value) {
  return await axiosClient.put(`/banks/${value.id}`, value);
}

//suppliers
export async function retrieveSupplierDB() {
  return await axiosClient.get("/suppliers");
}

export async function addSupplierDB(value) {
  return await axiosClient.post("/suppliers", value);
}

export async function deleteSupplierDB(id) {
  return await axiosClient.delete(`/suppliers/${id}`);
}

export async function bulkDeleteSupplierDB(ids) {
  return await axiosClient.post(`/suppliers/bulk_delete`, ids);
}

export async function editSupplierDB(value) {
  return await axiosClient.put(`/suppliers/${value.id}`, value);
}

//buyer
export async function retrieveBuyerDB() {
  return await axiosClient.get("/buyers");
}

export async function addBuyerDB(value) {
  return await axiosClient.post("/buyers", value);
}

export async function deleteBuyerDB(id) {
  return await axiosClient.delete(`/buyers/${id}`);
}

export async function bulkDeleteBuyerDB(ids) {
  return await axiosClient.post(`/buyers/bulk_delete`, ids);
}

export async function editBuyerDB(value) {
  return await axiosClient.put(`/buyers/${value.id}`, value);
}

//buyer Brands

/*-------------------------------------------------*/
/*           BUYER DEPARTMENTS CALLS                */
/*-------------------------------------------------*/
export async function retrieveBuyerDepartmentDB() {
  return await axiosClient.get("/buyer_departments");
}

export async function addBuyerDepartmentDB(value) {
  return await axiosClient.post("/buyer_departments", value);
}

export async function deleteBuyerDepartmentDB(id) {
  return await axiosClient.delete(`/buyer_departments/${id}`);
}

export async function bulkDeleteBuyerDepartmentDB(ids) {
  return await axiosClient.post(`/buyer_departments/bulk_delete`, ids);
}

export async function editBuyerDepartmentDB(value) {
  return await axiosClient.put(`/buyer_departments/${value.id}`, value);
}

/*-------------------------------------------------*/
/*           BUYER DEPARTMENTS CALLS                */
/*-------------------------------------------------*/
export async function retrieveSizeSetDB() {
  return await axiosClient.get("/size_sets");
}

export async function addSizeSetDB(value) {
  return await axiosClient.post("/size_sets", value);
}

export async function deleteSizeSetDB(id) {
  return await axiosClient.delete(`/size_sets/${id}`);
}

export async function bulkDeleteSizeSetDB(ids) {
  return await axiosClient.post(`/size_sets/bulk_delete`, ids);
}

export async function editSizeSetDB(value) {
  return await axiosClient.put(`/size_sets/${value.id}`, value);
}

/*-------------------------------------------------*/
/*           OVERSEAS OFFICES CALLS                */
/*-------------------------------------------------*/

export async function bulkDeleteOverseasOfficeDB(ids) {
  return await axiosClient.post(`/overseas_offices/bulk_delete`, ids);
}

export async function retrieveOverseasOfficesData() {
  return await axiosClient.get("/overseas_offices");
}

export async function addOverseasOfficeDB(value) {
  return await axiosClient.post("/overseas_offices", value);
}

export async function deleteOverseasOfficeDB(id) {
  return await axiosClient.delete(`/overseas_offices/${id}`);
}

export async function editOverseasOfficeDB(value) {
  return await axiosClient.put(`/overseas_offices/${value.id}`, value);
}
/*-------------------------------------------------*/
/*              COLORS API CALLS                   */
/*-------------------------------------------------*/
export async function bulkDeleteColorDB(ids) {
  return await axiosClient.post(`/colors/bulk_delete`, ids);
}

export async function retrieveColorsData() {
  return await axiosClient.get("/colors");
}

export async function addColorDB(value) {
  return await axiosClient.post("/colors", value);
}

export async function deleteColorDB(id) {
  return await axiosClient.delete(`/colors/${id}`);
}

export async function editColorDB(value) {
  return await axiosClient.put(`/colors/${value.id}`, value);
}

/*-------------------------------------------------*/
/*              SEASONS API CALLS                  */
/*-------------------------------------------------*/

export async function bulkDeleteSeasonDB(ids) {
  return await axiosClient.post(`/seasons/bulk_delete`, ids);
}

export async function retrieveSeasonsData() {
  return await axiosClient.get("/seasons");
}

export async function addSeasonDB(value) {
  return await axiosClient.post("/seasons", value);
}

export async function deleteSeasonDB(id) {
  return await axiosClient.delete(`/seasons/${id}`);
}

export async function editSeasonDB(value) {
  return await axiosClient.put(`/seasons/${value.id}`, value);
}
/*-------------------------------------------------*/
/*             PRODUCT-TYPES API CALLS             */
/*-------------------------------------------------*/

/*-------------------------------------------------*/
/*                 PRODUCT API CALLS               */
/*-------------------------------------------------*/

/*-------------------------------------------------*/
/*             BANK ACCOUNTS API CALLS             */
/*-------------------------------------------------*/

export async function retrieveBankAccountsData() {
  return await axiosClient.get("/bank_accounts");
}

export async function addBankAccountDB(value) {
  return await axiosClient.post("/bank_accounts", value);
}

export async function deleteBankAccountDB(id) {
  return await axiosClient.delete(`/bank_accounts/${id}`);
}

export async function bulkDeleteBankAccountDB(ids) {
  return await axiosClient.post(`/bank_accounts/bulk_delete`, ids);
}

export async function editBankAccountDB(value) {
  return await axiosClient.put(`/bank_accounts/${value.id}`, value);
}
/*-------------------------------------------------*/
/*               BUYER TERMS API CALLS             */
/*-------------------------------------------------*/

export async function bulkDeleteBuyerTermDB(ids) {
  return await axiosClient.post(`/buyer_terms/bulk_delete`, ids);
}

export async function retrieveBuyerTermsData() {
  return await axiosClient.get("/buyer_terms");
}

export async function addBuyerTermDB(value) {
  return await axiosClient.post("/buyer_terms", value);
}

export async function deleteBuyerTermDB(id) {
  return await axiosClient.delete(`/buyer_terms/${id}`);
}

export async function editBuyerTermDB(value) {
  return await axiosClient.put(`/buyer_terms/${value.id}`, value);
}

/*-------------------------------------------------*/
/*                COURIERS API CALLS               */
/*-------------------------------------------------*/

export async function bulkDeleteCourierDB(ids) {
  return await axiosClient.post(`/couriers/bulk_delete`, ids);
}

export async function retrieveCouriersData() {
  return await axiosClient.get("/couriers");
}

export async function addCourierDB(value) {
  return await axiosClient.post("/couriers", value);
}

export async function deleteCourierDB(id) {
  return await axiosClient.delete(`/couriers/${id}`);
}

export async function editCourierDB(value) {
  return await axiosClient.put(`/couriers/${value.id}`, value);
}
/*-------------------------------------------------*/
/*              EX-FACTORIES API CALLS             */
/*-------------------------------------------------*/

export async function bulkDeleteExFactoryDB(ids) {
  return await axiosClient.post(`/ex_factories/bulk_delete`, ids);
}

export async function retrieveExFactoriesData() {
  return await axiosClient.get("/ex_factories");
}

export async function addExFactoryDB(value) {
  return await axiosClient.post("/ex_factories", value);
}

export async function deleteExFactoryDB(id) {
  return await axiosClient.delete(`/ex_factories/${id}`);
}

export async function editExFactoryDB(value) {
  return await axiosClient.put(`/ex_factories/${value.id}`, value);
}
/*-------------------------------------------------*/
/*                FABRICS API CALLS                */
/*-------------------------------------------------*/

/*-------------------------------------------------*/
/*             FABRICS TYPES API CALLS             */
/*-------------------------------------------------*/

/*-------------------------------------------------*/
/*                  PORTS API CALLS                */
/*-------------------------------------------------*/

export async function bulkDeletePortDB(ids) {
  return await axiosClient.post(`/ports/bulk_delete`, ids);
}

export async function retrievePortsData() {
  return await axiosClient.get("/ports");
}

export async function addPortDB(value) {
  return await axiosClient.post("/ports", value);
}

export async function deletePortDB(id) {
  return await axiosClient.delete(`/ports/${id}`);
}

export async function editPortDB(value) {
  return await axiosClient.put(`/ports/${value.id}`, value);
}

/*-------------------------------------------------*/
/*                  User Roles API CALLS                */
/*-------------------------------------------------*/

export async function bulkDeleteUserRoleDB(ids) {
  return await axiosClient.post(`/app_user_roles/bulk_delete`, ids);
}

export async function retrieveUserRolesData() {
  return await axiosClient.get("/app_user_roles");
}

export async function addUserRoleDB(value) {
  return await axiosClient.post("/app_user_roles", value);
}

export async function deleteUserRoleDB(id) {
  return await axiosClient.delete(`/app_user_roles/${id}`);
}

export async function editUserRoleDB(value) {
  return await axiosClient.put(`/app_user_roles/${value.id}`, value);
}

/*-------------------------------------------------*/
/*                  User Access API CALLS                */
/*-------------------------------------------------*/

export async function bulkDeleteUserAccessDB(ids) {
  return await axiosClient.post(`/app_access_control/bulk_delete`, ids);
}

export async function retrieveUserAccessesData() {
  return await axiosClient.get("/app_access_control");
}

export async function addUserAccessDB(value) {
  return await axiosClient.post("/app_access_control/bulk_update", value);
}

export async function deleteUserAccessDB(id) {
  return await axiosClient.delete(`/app_access_control/${id}`);
}

export async function editUserAccessDB(value) {
  return await axiosClient.put(`/app_access_control/${value.id}`, value);
}

export async function retrieveAccessByUserIdDB(value) {

  return await axiosClient.post("/app_access_control/by_user_id", value);
}

/*-------------------------------------------------*/
/*               COUNTRIES API CALLS               */
/*-------------------------------------------------*/

export async function retrieveCountriesData() {
  return await axiosClient.get("/countries");
}

export async function addCountryDB(value) {
  return await axiosClient.post("/countries", value);
}

export async function deleteCountryDB(id) {
  return await axiosClient.delete(`/countries/${id}`);
}

export async function bulkDeleteCountryDB(ids) {
  return await axiosClient.post(`/countries/bulk_delete`, ids);
}

export async function editCountryDB(value) {
  return await axiosClient.put(`/countries/${value.id}`, value);
}
/*-------------------------------------------------*/
/*               CURRENCIES API CALLS              */
/*-------------------------------------------------*/

export async function retrieveCurrenciesData() {
  return await axiosClient.get("/currencies");
}

export async function addCurrencyDB(value) {
  return await axiosClient.post("/currencies", value);
}

export async function deleteCurrencyDB(id) {
  return await axiosClient.delete(`/currencies/${id}`);
}

export async function bulkDeleteCurrencyDB(ids) {
  return await axiosClient.post(`/currencies/bulk_delete`, ids);
}

export async function editCurrencyDB(value) {
  return await axiosClient.put(`/currencies/${value.id}`, value);
}
/*-------------------------------------------------*/
/*               FOB-TYPES API CALLS               */
/*-------------------------------------------------*/

export async function retrieveFobTypesData() {
  return await axiosClient.get("/fob_types");
}

export async function addFobTypeDB(value) {
  return await axiosClient.post("/fob_types", value);
}

export async function deleteFobTypeDB(id) {
  return await axiosClient.delete(`/fob_types/${id}`);
}

export async function bulkDeleteFobTypeDB(ids) {
  return await axiosClient.post(`/fob_types/bulk_delete`, ids);
}

export async function editFobTypeDB(value) {
  return await axiosClient.put(`/fob_types/${value.id}`, value);
}
