import axios from "axios";

const axiosClient = axios.create({
  // baseURL: "http://dmdb.clonestudiobd.com/api/",
  baseURL: "https://stepup-trade-api.clonestudiobd.com/api/",
  // baseURL: "http://localhost:5000/api/",
  // baseURL: "http://rdlerp.renaissance.com.bd:60001/api/",
});

export default axiosClient;
